import {DELETE, GET, POST, PUT, UPLOAD} from './ApiContract';

//drivertype services
export const GetDriverTypeService = (payload) => {
    return GET('clients/drivertypes/list', payload);
}

export const AddDriverTypeService = (payload) => {
    return POST('clients/drivertypes/add', payload);
}

export const DeleteDriverTypeService = (payload) => {
    return DELETE('clients/drivertypes/delete', payload);
}

export const EditDriverTypeService = (payload) => {
    return POST('clients/drivertypes/edit', payload);
}

//job source services
export const GetJobSourceService = (payload) => {
    return GET('clients/jobsources/list', payload);
}

export const AddJobSourceService = (payload) => {
    return POST('clients/jobsources/add', payload);
}

export const DeleteJobSourceService = (payload) => {
    return DELETE('clients/jobsources/delete', payload);
}

export const EditJobSourceService = (payload) => {
    return POST('clients/jobsources/edit', payload);
}

//load types services
export const GetLoadTypeService = (payload) => {
    return GET('clients/loadtypes/list', payload);
}

export const AddLoadTypeService = (payload) => {
    return POST('clients/loadtypes/add', payload);
}

export const DeleteLoadTypeService = (payload) => {
    return DELETE('clients/loadtypes/delete', payload);
}

export const EditLoadTypeService = (payload) => {
    return POST('clients/loadtypes/edit', payload);
}

//tax form types services
export const GetTaxFormTypeService = (payload) => {
    return GET('clients/taxformtypes/list', payload);
}

export const AddTaxFormTypeService = (payload) => {
    return POST('clients/taxformtypes/add', payload);
}

export const DeleteTaxFormTypeService = (payload) => {
    return DELETE('clients/taxformtypes/delete', payload);
}

export const EditTaxFormTypeService = (payload) => {
    return POST('clients/taxformtypes/edit', payload);
}

//trailer types services
export const GetTrailerTypeService = (payload) => {
    return GET('clients/trailertypes/list', payload);
}

export const AddTrailerTypeService = (payload) => {
    return POST('clients/trailertypes/add', payload);
}

export const DeleteTrailerTypeService = (payload) => {
    return DELETE('clients/trailertypes/delete', payload);
}

export const EditTrailerTypeService = (payload) => {
    return POST('clients/trailertypes/edit', payload);
}

//companies services
export const GetCompaniesService = (payload) => {
    return POST('companies/list', payload);
}

export const GetCompanyListService = (payload) => {
    return GET('companies/get', payload);
}

export const AddCompaniesService = (payload) => {
    return UPLOAD('companies/add', payload);
}

export const DeleteCompaniesService = (payload) => {
    return DELETE('companies/delete', payload);
}

export const EditCompaniesService = (payload) => {
    return UPLOAD('companies/edit', payload);
}

//job list services
export const GetJoblistService = (payload) => {
    return POST('clients/list', payload);
}

export const GetInactiveJoblistService = (payload) => {
    return POST('clients/inactivelist', payload);
}

export const AddJoblistService = (payload) => {
    return POST('clients/add', payload);
}

export const DeleteJoblistService = (payload) => {
    return DELETE('clients/delete', null, payload);
}

export const ChangeJobStatusService = (payload) => {
    return PUT('clients/changeStatus', payload);
}

export const EditJoblistService = (payload) => {
    return PUT('clients/edit', payload);
}

export const GetJobByIdService = (payload) => {
    return GET('clients/get', payload);
}

export const TextSearchService = (payload) => {
    return POST('common/search/text', payload);
}

export const FetchDetailsService = (payload) => {
    return GET('common/fetch/data', payload);
}

//job application service
export const GetJobApplicationlistService = (payload) => {
    return POST('clients/applications/list', payload);
}

export const GetInactiveJobApplicationlistService = (payload) => {
    return POST('clients/applications/inactivelist', payload);
}

export const DeleteJobApplicationlistService = (payload) => {
    return DELETE('clients/applications/delete', null, payload);
}

export const ChangeJobApplicationStatusService = (payload) => {
    return PUT('clients/applications/changeStatus', payload);
}

export const EditJobApplicationlistService = (payload) => {
    return PUT('clients/applications/edit', payload);
}

export const GetJobApplicationByIdService = (payload) => {
    return GET('clients/applicationfixer/get', payload);
}
