import {GET, POST} from './ApiContract';

export const LoginService = (payload, urlParams) => {
    return POST('users/gateway/trmadminlogin', payload, urlParams);
}

export const LoginiService = (payload, urlParams) => {
    return POST('users/gateway/crmilogin', payload, urlParams);
}

export const UserInfoService = (payload) => {
    return GET('users/get', payload);
}

export const UserMenusService = (payload) => {
    return GET('users/trmadminmenus', payload);
}

export const ChangePasswordService = (payload) => {
    return POST('users/changepassword', payload);
}

export const ForgotpasswordService = (payload) => {
    return POST('users/gateway/forgotpassword', payload);
}

export const ResetPasswordService = (payload) => {
    return POST('users/gateway/resetlogin', payload);
}

export const CheckResetService = (payload) => {
    return GET('users/gateway/checkresetlink', payload);
}

export const SetPasswordService = (payload) => {
    return POST('users/gateway/setlogin', payload);
}

export const LogoutService = (payload) => {
    return GET('users/csrflogout', payload);
}
