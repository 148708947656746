import {DELETE, GET, POST} from './ApiContract';

/**************COUNTRIES SERVICE**************/

export const GetCountryService = (payload) => {
    return GET('common/country/getall', payload);
}
export const GetActiveCountryService = (payload) => {
    return GET('common/country/get', payload);
}
export const AddCountryService = (payload) => {
    return POST('common/country/add', payload);
}
export const EditCountryService = (payload) => {
    return POST('common/country/edit', payload);
}
export const DeleteCountryService = (payload) => {
    return DELETE('common/country/changestatus', payload);
}

/**************STATES SERVICES**************/

export const GetStateService = (payload) => {
    return GET('common/state/getall', payload);
}


export const GetStatesByCountryService = (cid1, payload) => {
    return GET('common/state/getbycountry?countryId=' + cid1, payload);
}
export const GetActiveStateService = (cid, payload) => {
    return GET('common/state/get?countryId=' + cid, payload);
}

export const AddStateService = (payload) => {
    return POST('common/state/add', payload);
}
export const EditStateService = (payload) => {
    return POST('common/state/edit', payload);
}
export const DeleteStateService = (payload) => {
    return DELETE('common/state/changestatus', payload);
}

/**************CITIES SERVICES**************/

export const GetCityService = (sid, payload) => {
    return GET('common/city/getall?stateId=' + sid, payload);
}
//  return GET('common/city/getall?stateId=1', payload);
export const GetActiveCityService = (sid, payload) => {
    return GET('common/city/get?stateId=' + sid, payload);
}

export const AddCityService = (payload) => {
    return POST('common/city/add', payload);
}
export const EditCityService = (payload) => {
    console.log(payload)
    return POST('common/city/edit', payload);
}
export const DeleteCityService = (payload) => {
    return DELETE('common/city/changestatus', payload);
}
/**************ZIPCODES SERVICES**************/

export const GetZipService = (cyid, payload) => {
    return GET('common/zip/getall?cityId=' + cyid, payload);
}

export const AddZipService = (payload) => {
    return POST('common/zip/add', payload);
}
export const EditZipService = (payload) => {
    return POST('common/zip/edit', payload);
}
export const DeleteZipService = (payload) => {
    return DELETE('common/zip/changestatus', payload);
}


